export enum Action {
  Base = "http://127.0.0.1:8000",
  API = "http://127.0.0.1:8000/api",

  Province = "administrative_area/province",
  District = "administrative_area/district",
  Palika = "administrative_area/palika",

  GetProvinceList = "administrative_area/get/province/list",
  GetDistrictList = "administrative_area/get/district/list",
  GetPalikaList = "administrative_area/get/municipality/list",
  GetWardList = "administrative_area/get/ward/list",
  GetAllLocationsList = "administrative_area/get/locations/all/list",

  ObtainToken = "auth/token",
  RefreshToken = "auth/refresh/token",
  VerifyToken = "auth/verify/token",

  ForgotPassword = "auth/password/forgot",
  ResetPassword = "auth/password/reset",
  ChangePassword = "auth/password/change",
  CheckCurrentPassword = "auth/password/check",

  ActivateAccount = "auth/account/activate",
  UserExists = "auth/user/checkexists",
  BasicUserInfo = "auth/basic_info",
  User = "auth/user",
  UserRole = "auth/role",

  Markets = "market/markets",
  Product = "market/products",
  ProductPrice = "market/products-prices",
  ProductPriceDetail = "market/products-prices/<id>",
  Category = "market/products-categories",
  Marketplace = "market/marketplace",
  Transport = "market/transport",
  News = "market/news",
  MMCMembers = "market/mmc_members",
  MMCMembersStaff = "market/mmc_staff",
  PaginatedMMCMembersStaff = "market/mmc_staff/paginated_mmc_member_staff",
  MMCMembersStaffDetails = "market/mmc_staff/<id>",
  Trader = "traders/traders",

  Summary = "market/summary",
  ProductPriceList = "market/product_price_list",
  LineChart = "market/raw_data/price_product_line",
  TimeSeriesLineChart = "market/raw_data_highcharts/price_product_line",
  TransportChart = "market/generic_raw_data_highcharts/transport_market",
  ExportProductPriceList = "market/reports/product-prices/export/<marketId>/<nepDate>",

  ExportProductPriceDateRangeList = "market/reports/product-prices/export/<marketId>/<nepStartDate>/<nepEndDate>",
  ProductPriceDateRangeList = "market/reports/product-prices/date_range_data/<marketId>/<nepStartDate>/<nepEndDate>",

  ProductPriceDate = "market/reports/product-prices/dates/<marketId>",
  ExportTransportList = "market/exports-transports",

  ExportTransportDateRangeList = "market/reports/transports/export/<marketId>/<nepStartDate>/<nepEndDate>",
  ExportDistrictWiseTransportDateRangeList = "market/reports/transports/export_district_wise/<marketId>/<nepStartDate>/<nepEndDate>",
  TransportDateRangeList = "market/reports/transports/date_range_data/<marketId>/<nepStartDate>/<nepEndDate>",

  ExportTransactionDateRangeList = "market/reports/transactions/export/<marketId>/<nepStartDate>/<nepEndDate>",
  TransactionDateRangeList = "market/reports/transactions/date_range_data/<marketId>/<nepStartDate>/<nepEndDate>",

  PaginatedMmcMember = "market/paginated_mmc_members",
  Designation = "market/designation",
}
