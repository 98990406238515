import axios from "axios";
import Vue from "vue";
import { Action } from "@/store/actionType";
import { eventHub } from "@/utils/eventHub";
import JWTStore from "@/store/modules/jwt";
import router from "@/router";

export let Base = process.env.VUE_APP_API_BASE;

if (Base && Base != "" && !Base.includes("http")) {
  Base = location.protocol + "//" + Base;
}

const actionBase = axios.create({
  baseURL: Base + "/api",
});

actionBase.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("access_token");

actionBase.interceptors.response.use(
  function (response: any) {
    return response;
  },
  async function (error: any) {
    if (error.response.status === 401) {
      if (
        error.response.data.code === "token_not_valid" &&
        (error.response.data.detail === "Token is invalid or expired" ||
          error.response.data.detail === "Token 'exp' claim has expired")
      ) {
        JWTStore.logOut();
      } else if (
        error.response.data.code === "token_not_valid" &&
        error.response.data.messages[0]["token_class"] === "AccessToken"
      ) {
        const refresh_token = localStorage.getItem("refresh_token");
        if (refresh_token) {
          const tok = await JWTStore.getAccessToken();
          return new Promise((resolve) => {
            error.response.config.headers.Authorization =
              "Bearer " + localStorage.getItem("access_token");
            resolve(axios(error.response.config));
          });
        } else {
          clearJWT();
        }
      }
    }
    if (error.response.config.url == `/${Action.RefreshToken}/`) {
      if (error.response.status == 401) {
        if (error.response.data.message != "NoError") {
          JWTStore.logOut();
          router.push({ name: "Home" });
          // Notify.create({
          //   message: "You have been logged out! Please login again",
          //   type: "negative",
          //   position: "top-right",
          // });
          // const toast = useToast();
          // if (toast) {
          //   toast.show(
          //     {
          //       title: "Logged out",
          //       body: "You have been logged out! Please login again",
          //     },
          //     { variant: "danger" }
          //   );
          // }
          return Promise.reject(error);
        }
      }
    }

    return Promise.reject(error);
  }
);

const vm = new Vue();

export async function postApi(
  action: string,
  param: any,
  extra?: any
): Promise<any> {
  // eventHub.$emit("before-request");
  return await new Promise((resolve, reject) => {
    setJWT();
    if (
      action.includes("forgotpassword") ||
      action.includes("resetpassword") ||
      action.includes("changesuccess")
    ) {
      clearJWT();
    }
    if (!action.includes("?") && action[action.length - 1] != "/") {
      action += "/";
    }

    const promise = actionBase.post(`/${action}`, param, extra);

    promise.then(
      (res: any) => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      (err: any) => {
        eventHub.$emit("after-response");
        if (err.response) {
          if (err.response.status === 404) {
            // vm.$notify.error("Not Found");
            eventHub.$emit("notify", "Not Found", "Not Found", "warning");
          } else if (err.response.status === 500) {
            // vm.$notify.error("Internal Server Error");
            eventHub.$emit(
              "notify",
              "Server Error",
              "Internal Server Error 500",
              "danger"
            );
          }
        } else {
          // vm.$notify.error("Network Error");
          eventHub.$emit("notify", "Network Error", "Network Error", "danger");
        }
        reject(err);
      }
    );

    // For error with status code 400 Bad Request
    promise.catch((err: any) => {
      eventHub.$emit("after-response");
      if (err.response.status === 400 && err.response) {
        if (err.response.status === 400 && err.response.data.new_username) {
          for (let i = 0; i < err.response.data.new_username.length; i++) {
            // vm.$notify.error(err.response.data.new_username[i]);
            eventHub.$emit(
              "notify",
              "ERROR",
              err.response.data.new_username[i],
              "danger"
            );
          }
        }
        // else if (
        //   err.response.status === 400 &&
        //   err.response.data.refresh[0] === "This field may not be null."
        // ) {
        //   JWTStore.logOut();
        // }
        else if (
          err.response.status === 400 &&
          err.response.data.code === "token_not_valid" &&
          err.response.data.detail === "Invalid Token"
        ) {
          // vm.$snotify.error("Verifying Token", "Error");
        } else if (
          err.response.status === 400 &&
          err.response.data.non_field_errors
        ) {
          for (let i = 0; i < err.response.data.non_field_errors.length; i++) {
            // vm.$notify.error(err.response.data.non_field_errors[i]);
            eventHub.$emit(
              "notify",
              "ERROR",
              err.response.data.non_field_errors[i],
              "danger"
            );
          }
        } else if (err.response.status === 400 && err.response.data.username) {
          for (let i = 0; i < err.response.data.username.length; i++) {
            // vm.$notify.error(err.response.data.username[i]);
            eventHub.$emit(
              "notify",
              "ERROR",
              err.response.data.username[i],
              "danger"
            );
          }
        } else if (
          err.response.status === 400 &&
          err.response.data.new_password
        ) {
          for (let i = 0; i < err.response.data.new_password.length; i++) {
            // vm.$notify.error(err.response.data.new_password[i]);
            eventHub.$emit(
              "notify",
              "ERROR",
              err.response.data.new_password[i],
              "danger"
            );
          }
        } else if (
          err.response.status === 400 &&
          err.response.data.current_password
        ) {
          for (let i = 0; i < err.response.data.current_password.length; i++) {
            // vm.$notify.error(err.response.data.current_password[i]);
            eventHub.$emit(
              "notify",
              "ERROR",
              err.response.data.current_password[i],
              "danger"
            );
          }
        } else if (err.response.status === 400 && err.response.data.message) {
          // vm.$notify.error(err.response.data.message);
          eventHub.$emit(
            "notify",
            "ERROR",
            err.response.data.Message,
            "danger"
          );
        } else if (err.response.status === 400 && err.response.data.Message) {
          // vm.$notify.error(err.response.data.Message);
          eventHub.$emit(
            "notify",
            "ERROR",
            err.response.data.Message,
            "danger"
          );
        } else if (err.response.status === 400) {
          // vm.$notify.error("Error");
          eventHub.$emit("notify", "ERROR", "error", "danger");
        }
        reject(err);
      }
    });

    // For error with status code 401 Unauthorized
    promise.catch((err: any) => {
      eventHub.$emit("after-response");
      if (err.response.status === 401) {
        // vm.$notify.error(err.response.data.detail);
        eventHub.$emit("notify", "ERROR", err.response.data.detail, "danger");
        reject(err);
      }
    });

    // For error with status code 403 Forbidden
    promise.catch((err: any) => {
      eventHub.$emit("after-response");
      if (err.response.status === 403) {
        if (err.response.data.Message) {
          // vm.$notify.error(err.response.data.Message);
          eventHub.$emit(
            "notify",
            "ERROR",
            err.response.data.Message,
            "danger"
          );
        } else if (err.response.data.message) {
          // vm.$notify.error(err.response.data.message);
          eventHub.$emit(
            "notify",
            "ERROR",
            err.response.data.Message,
            "danger"
          );
        }
        reject(err);
      }
    });

    // promise.catch(err => {
    //   eventHub.$emit("after-response");
    //   reject(err);
    // });
  });
}

export async function patchApi(
  action: string,
  data: any,
  extra?: any
): Promise<any> {
  // eventHub.$emit("before-request");
  return await new Promise((resolve, reject) => {
    setJWT();
    if (
      action.includes("forgotpassword") ||
      action.includes("resetpassword") ||
      action.includes("changesuccess")
    ) {
      clearJWT();
    }

    const promise = actionBase.patch(`/${action}/`, data, extra);
    promise.then(
      (res: any) => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      (err: any) => {
        eventHub.$emit("after-response");
        if (err.response) {
          if (err.response.status === 404) {
            // vm.$notify.error("Not Found");
            eventHub.$emit("notify", "Not Found", "Not Found", "danger");
          } else if (err.response.status === 500) {
            // vm.$notify.error("Internal Server Error");
            eventHub.$emit(
              "notify",
              "Server Error",
              "Internal Server Error 500",
              "danger"
            );
          }
        } else {
          // vm.$notify.error("Network Error");
          eventHub.$emit("notify", "Network Error", "Network Error", "danger");
        }
        reject(err);
      }
    );

    // For error with status code 400 Bad Request
    promise.catch((err: any) => {
      eventHub.$emit("after-response");
      if (err.response.status === 400 && err.response) {
        if (err.response.status === 400 && err.response.data.new_username) {
          for (let i = 0; i < err.response.data.new_username.length; i++) {
            // vm.$notify.error(err.response.data.new_username[i]);
            eventHub.$emit(
              "notify",
              "ERROR",
              err.response.data.new_username[i],
              "danger"
            );
          }
        }
        // else if (
        //   err.response.status === 400 &&
        //   err.response.data.refresh[0] === "This field may not be null."
        // ) {
        //   JWTStore.logOut();
        // }
        else if (
          err.response.status === 400 &&
          err.response.data.code === "token_not_valid" &&
          err.response.data.detail === "Invalid Token"
        ) {
          // vm.$snotify.error("Verifying Token", "Error");
        } else if (
          err.response.status === 400 &&
          err.response.data.non_field_errors
        ) {
          for (let i = 0; i < err.response.data.non_field_errors.length; i++) {
            // vm.$notify.error(err.response.data.non_field_errors[i]);
            eventHub.$emit(
              "notify",
              "ERROR",
              err.response.data.non_field_errors[i],
              "danger"
            );
          }
        } else if (err.response.status === 400 && err.response.data.username) {
          for (let i = 0; i < err.response.data.username.length; i++) {
            // vm.$notify.error(err.response.data.username[i]);
            eventHub.$emit(
              "notify",
              "ERROR",
              err.response.data.Message,
              "danger"
            );
          }
        } else if (
          err.response.status === 400 &&
          err.response.data.new_password
        ) {
          for (let i = 0; i < err.response.data.new_password.length; i++) {
            // vm.$notify.error(err.response.data.new_password[i]);
            eventHub.$emit(
              "notify",
              "ERROR",
              err.response.data.new_password[i],
              "danger"
            );
          }
        } else if (
          err.response.status === 400 &&
          err.response.data.current_password
        ) {
          for (let i = 0; i < err.response.data.current_password.length; i++) {
            // vm.$notify.error(err.response.data.current_password[i]);
            eventHub.$emit(
              "notify",
              "ERROR",
              err.response.data.current_password[i],
              "danger"
            );
          }
        } else if (err.response.status === 400 && err.response.data.message) {
          // vm.$notify.error(err.response.data.message);
          eventHub.$emit(
            "notify",
            "ERROR",
            err.response.data.Message,
            "danger"
          );
        } else if (err.response.status === 400 && err.response.data.Message) {
          // vm.$notify.error(err.response.data.Message);
          eventHub.$emit(
            "notify",
            "ERROR",
            err.response.data.Message,
            "danger"
          );
        } else if (err.response.status === 400) {
          // vm.$notify.error("Error");
          eventHub.$emit("notify", "ERROR", "error", "danger");
        }
        reject(err);
      }
    });

    // For error with status code 401 Unauthorized
    promise.catch((err: any) => {
      eventHub.$emit("after-response");
      if (err.response.status === 401) {
        // vm.$notify.error(err.response.data.detail);
        eventHub.$emit("notify", "ERROR", err.response.data.detail, "danger");

        reject(err);
      }
    });

    // For error with status code 403 Forbidden
    promise.catch((err: any) => {
      eventHub.$emit("after-response");
      if (err.response.status === 403) {
        if (err.response.data.Message) {
          // vm.$notify.error(err.response.data.Message);
          eventHub.$emit(
            "notify",
            "ERROR",
            err.response.data.Message,
            "danger"
          );
        } else if (err.response.data.message) {
          // vm.$notify.error(err.response.data.message);
          eventHub.$emit(
            "notify",
            "ERROR",
            err.response.data.Message,
            "danger"
          );
        }
        reject(err);
      }
    });

    // promise.catch(err => {
    //   eventHub.$emit("after-response");
    //   reject(err);
    // });
  });
}

export async function getApi(
  action: string,
  params = "",
  config?: any
): Promise<any> {
  return await new Promise((resolve, reject) => {
    setJWT();
    if (action === "crops" || action === "cropcategorynavbar") {
      clearJWT();
    }
    console.log("action", action);
    eventHub.$emit("before-request");
    actionBase.get(`/${action}/${params}`, config).then(
      (res: any) => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      (err: any) => {
        eventHub.$emit("after-response");
        if (err.response) {
          // vm.$notify.error(err.response.data.Message);
          eventHub.$emit(
            "notify",
            "ERROR",
            err.response.data.Message,
            "danger"
          );
        } else {
          // vm.$notify.error("Network Error");
          eventHub.$emit("notify", "Network Error", "Network Error", "danger");
        }
      }
    );
  });
}

export async function getApiWithoutBack(action: string): Promise<any> {
  return await new Promise((resolve, reject) => {
    eventHub.$emit("before-request");
    actionBase.get("/" + action).then(
      (res: any) => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      (err: any) => {
        eventHub.$emit("after-response");
        if (err.response) {
          // vm.$notify.error(err.response.data.Message);
          eventHub.$emit(
            "notify",
            "ERROR",
            err.response.data.Message,
            "danger"
          );
        } else {
          // vm.$notify.error("Network Error");
          eventHub.$emit("notify", "Network Error", "Network Error", "danger");
        }
      }
    );
  });
}

export async function deleteApi(action: string, param: any): Promise<any> {
  eventHub.$emit("before-request");
  return await new Promise((resolve, reject) => {
    actionBase.delete(`/${action}/${param}/`).then(
      (res: any) => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      (err: any) => {
        eventHub.$emit("after-response");
        if (err.response) {
          if (err.response.data.Message) {
            // vm.$notify.error(err.response.data.Message);
            eventHub.$emit(
              "notify",
              "ERROR",
              err.response.data.Message,
              "danger"
            );
          }
          if (err.response.data.message) {
            // vm.$notify.error(err.response.data.message);
            eventHub.$emit(
              "notify",
              "ERROR",
              err.response.data.Message,
              "danger"
            );
          }
        } else {
          // vm.$notify.error("Network Error");
          eventHub.$emit("notify", "Network Error", "Network Error", "danger");
        }
      }
    );
  });
}

export async function deleteApiWithoutBack(
  action: string,
  param: any
): Promise<any> {
  eventHub.$emit("before-request");
  return await new Promise((resolve, reject) => {
    actionBase.delete(`/${action}/${param}`).then(
      (res: any) => {
        eventHub.$emit("after-response");
        resolve(res.data);
      },
      (err: any) => {
        eventHub.$emit("after-response");
        if (err.response) {
          // vm.$notify.error(err.response.data.Message);
          eventHub.$emit(
            "notify",
            "ERROR",
            err.response.data.Message,
            "danger"
          );
        } else {
          // vm.$notify.error("Network Error");
          eventHub.$emit("notify", "Network Error", "Network Error", "danger");
        }
      }
    );
  });
}

export function setJWT() {
  if (localStorage.getItem("access_token")) {
    actionBase.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("access_token");
  } else {
    clearJWT();
    // console.log("access token: " + localStorage.getItem("access_token"));
  }
}

export function clearJWT() {
  delete actionBase.defaults.headers.common["Authorization"];
}

// Make Axios play nice with Django CSRF
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
