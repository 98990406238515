import { postApi, getApi } from "@/store/api/api";
import { Action } from "@/store/actionType";
import {
  userInfo,
  forgotPassword,
  resetPassword,
  activate,
  changePassword,
} from "@/store/model/jwt";

export default new (class JWTAPI {
  public async getJWT(payload: userInfo): Promise<any> {
    const response = await postApi(Action.ObtainToken, payload);
    return response as userInfo;
  }

  public async getAccessToken(refreshtoken: any): Promise<any> {
    const response = await postApi(Action.RefreshToken, refreshtoken);
    return response as any;
  }

  public async verifyAccessToken(accesstoken: any): Promise<any> {
    const response = await postApi(Action.VerifyToken, accesstoken);
    return response as any;
  }

  public async forgotPassword(email: forgotPassword): Promise<any> {
    const response = await postApi(Action.ForgotPassword, email);
    return response as any;
  }

  public async resetPassword(data: resetPassword): Promise<any> {
    const response = await postApi(Action.ResetPassword, data);
    return response as any;
  }

  public async changePassword(data: changePassword): Promise<any> {
    const response = await postApi(Action.ChangePassword, data);
    return response as any;
  }

  public async checkCurrentPassword(params: any): Promise<any> {
    const url = `${Action.CheckCurrentPassword}`;
    const response = await postApi(url, params);
    return response as any;
  }

  public async activate(data: activate): Promise<any> {
    const response = await postApi(Action.ActivateAccount, data);
    return response as any;
  }

  public async userexists(username: any): Promise<any> {
    const response = await postApi(Action.UserExists, username, {});
    return response as any;
  }

  public async getBasicUserInfo(id: any): Promise<any> {
    const url = `${Action.BasicUserInfo}/${id}`;
    const response = await getApi(url);
    return response;
  }
})();
