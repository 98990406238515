const generalRoute = {
  path: "/",
  component: () =>
    import(/* webpackChunkName: "home" */ "@/views/layout/Layout.vue"),
  children: [
    {
      path: "",
      name: "Home",
      meta: { title: "कृषि बजार व्यवस्थापन समिति बुटवल | गृह " },

      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/general/HomeScreen.vue"),
    },

    {
      path: "/not-permitted",
      name: "NotPermitted",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "@/views/general/misc/NotPermittedScreen.vue"
        ),
    },
  ],
};

export default generalRoute;
