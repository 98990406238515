import Vue from "vue";

import "./styles/quasar.sass";
import "@quasar/extras/material-icons/material-icons.css";
import iconSet from "quasar/icon-set/fontawesome-v5.js";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import colors from 'quasar/src/utils/colors.js';import Notify from 'quasar/src/plugins/Notify.js';import Quasar from 'quasar/src/vue-plugin.js';;

Vue.use(Quasar, {
  config: {
    brand: {
      primary: "#758d39",
      secondary: "#6d3220",
    },
  },
  plugins: { Notify },
  // iconSet: iconSet,
});
