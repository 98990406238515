const userRoute = {
  path: "/",
  component: () =>
    import(/* webpackChunkName: "about" */ "@/views/layout/Layout.vue"),
  children: [
    {
      path: "/user",
      name: "Agent",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/user/AgentScreen.vue"
        ),
    },

    {
      path: "/create-user",
      name: "CreateUser",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/user/CreateAgentScreen.vue"
        ),
    },
    
  ],
};

export default userRoute;
