import store from "@/store";
import
  {
    Module,
    VuexModule,
    Mutation,
    MutationAction,
    Action,
    getModule,
  } from "vuex-module-decorators";
import API from "@/store/api/jwt";
import
  {
    userInfo,
    forgotPassword,
    resetPassword,
    activate,
    changePassword,
    BasicUserInfo,
  } from "@/store/model/jwt";
import { setJWT, clearJWT } from "@/store/api/api";
import { eventHub } from "@/utils/eventHub";
import Vue from "vue";
import router from "@/router";
// import i18n from "@/i18n.ts";

const vm = new Vue();

@Module({ name: "JWTStore", dynamic: true, store })
class JWTModule extends VuexModule
{
  isAuthenticated: any = localStorage.getItem("access_token") || null;
  acc: any = null;

  role: any = localStorage.getItem("role") || null;
  UserExists = false;
  public BasicUserInfo: BasicUserInfo = {} as BasicUserInfo;

  @MutationAction
  async getJWT(payload: userInfo)
  {
    const obj = await API.getJWT(payload);
    console.log("OBJ -> ", obj);
    localStorage.setItem("refresh_token", obj.refresh);
    localStorage.setItem("access_token", obj.access);
    localStorage.setItem("market_id", obj.user_detail.market_id);
    localStorage.setItem("role", obj.user_detail.role);
    localStorage.setItem("id", obj.user_detail.id);

    return { isAuthenticated: true as boolean };
  }

  async verfiyAccessToken()
  {
    await API.verifyAccessToken({
      accessToken: localStorage.getItem("access_token"),
    })
      .then((res) =>
      {
        return true;
      })
      .catch((err) =>
      {
        return false;
      });
    return false;
  }

  @Action
  async validateJWT()
  {
    let verifyAccessToken = false;
    await API.verifyAccessToken({
      token: localStorage.getItem("access_token"),
    })
      .then((res) =>
      {
        verifyAccessToken = true;
      })
      .catch((err) =>
      {
        verifyAccessToken = false;
      });
    return verifyAccessToken;
  }

  @Action
  async getAccessToken()
  {
    const acc = await API.getAccessToken({
      refresh: localStorage.getItem("refresh_token"),
    }).catch((err) =>
    {
      this.logOut();
    });

    localStorage.removeItem("access_token");
    localStorage.setItem("access_token", acc.access);
  }

  get loggedIn()
  {
    // return localStorage.getItem("access_token") !== null;
    return this.isAuthenticated;
  }

  @Mutation
  logOut()
  {
    this.role = null;
    this.isAuthenticated = false;
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    // localStorage.removeItem("role");
    clearJWT();
    // router.replace("/");
    // location.replace("/");
    // eventHub.$emit("reloadPage");
    // vm.$notify.warning(i18n.t('logoutMessage').toString());
    // vm.$notify.warning("Logout successfull.");
    vm.$q.notify("Logout Sucessfull. redirecting...");
    router.push({ name: "Home" }).catch((error) => console.log(error));
    // eventHub.$emit("fireLoginAfterLogout");
  }

  @Action
  async forgotPassword(email: forgotPassword)
  {
    const repass = await API.forgotPassword(email);
  }

  @Action
  async resetPassword(data: resetPassword)
  {
    const conrepass = await API.resetPassword(data);
  }

  @Action
  async changePassword(data: changePassword)
  {
    const pass = await API.changePassword(data);
  }

  @Action
  async checkCurrentPassword(data: { old_password: any })
  {
    const response = await API.checkCurrentPassword(data);
    return response as any;
  }

  @Action
  async activate(data: activate)
  {
    const activate = await API.activate(data);
    return activate;
  }

  @MutationAction
  async userexists(username: any)
  {
    const obj = await API.userexists({ username: username });
    return { UserExists: obj };
  }

  @MutationAction
  async getBasicUserInfo(): Promise<any>
  {
    const userId = localStorage.getItem("id");
    const response = await API.getBasicUserInfo(userId);
    return {
      BasicUserInfo: response,
    };
  }
}

export default getModule(JWTModule);
