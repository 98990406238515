import { roleChecker } from "@/utils/jwt";
import JWTStore from "@/store/modules/jwt";

const detailRoute = {
  path: "/",
  component: () =>
    import(/* webpackChunkName: "about" */ "@/views/layout/Layout.vue"),
  children: [
    {
      path: "/about",
      name: "About",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/general/AboutScreen.vue"
        ),
    },
    {
      path: "/contact",
      name: "Contact",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/general/ContactScreen.vue"
        ),
    },
    {
      path: "/price-monitor",
      name: "PriceMonitor",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/general/PriceMonitorScreen.vue"
        ),
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      meta: {
        needsAuth: true,
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker(["Agent", "Entrier", "AgentAndEntrier", "Superuser"])
          ) {
            next();
          } else {
            next({
              name: "NotPermitted",
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/DashboardScreen.vue"
        ),
    },
    {
      path: "/transport",
      name: "Transport",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/transport/TransportScreen.vue"
        ),
    },
    {
      path: "/create-transport",
      name: "CreateTransport",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/transport/CreateTransportScreen.vue"
        ),
    },
    {
      path: "/edit-transport/:id",
      name: "EditTransport",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/transport/CreateTransportScreen.vue"
        ),
    },

    {
      path: "/reports",
      name: "Reports",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/reports/ReportsScreen.vue"
        ),
    },

    {
      path: "/reports/price",
      name: "PriceReport",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/reports/PriceReport.vue"
        ),
    },

    {
      path: "/reports/transport",
      name: "TransportReport",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/reports/TransportReport.vue"
        ),
    },

    {
      path: "/reports/transaction",
      name: "TransactionReport",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/reports/TransactionReport.vue"
        ),
    },

    {
      path: "/news",
      name: "News",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/news/NewsScreen.vue"
        ),
    },

    {
      path: "/news-list",
      name: "NewsList",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/general/NewsListScreen.vue"
        ),
    },
    {
      path: "/news/:id",
      name: "NewsDetail",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/general/NewsDetailScreen.vue"
        ),
    },
    {
      path: "/create-news",
      name: "CreateNews",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/news/CreateNewsScreen.vue"
        ),
    },

    {
      path: "/edit-news/:id",
      name: "EditNews",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/news/CreateNewsScreen.vue"
        ),
    },

    {
      path: "/trader",
      name: "Trader",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/trader/TraderScreen.vue"
        ),
    },

    {
      path: "/trader/:id",
      name: "TraderDetail",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/trader/TraderDetailScreen.vue"
        ),
    },

    {
      path: "/create-trader",
      name: "CreateTrader",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/trader/CreateTraderScreen.vue"
        ),
    },

    {
      path: "/edit-trader/:id",
      name: "EditTrader",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/trader/CreateTraderScreen.vue"
        ),
    },

    // Market management committee routes

    {
      path: "/mmc-members",
      name: "MmcMemberList",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/mmc_member/MmcMembersView.vue"
        ),
    },

    {
      path: "/mmc-member/create",
      name: "CreateMmc",
      component: () => import("@/views/admin/mmc_member/MmcMembersForm.vue"),
    },

    {
      path: "/mmc-member/edit/:id",
      name: "EditMmc",
      component: () => import("@/views/admin/mmc_member/MmcMembersForm.vue"),
    },

    // routes to analysis part

    {
      path: "/analytics",
      name: "Analytics",
      component: () =>
        import(
          /* webpackChunkName: "about" */ "@/views/admin/analytics/AnalyticsScreen.vue"
        ),
    },
  ],
};

export default detailRoute;
